var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticStyle: { height: "100%", "padding-bottom": "200px" },
      attrs: { bordered: false }
    },
    [
      _c(
        "div",
        { staticClass: "table-page-search-wrapper" },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.formData, layout: "inline" } },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "性别", prop: "sex" } },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              title: "性别",
                              dictCode: "sex",
                              placeholder: "请选择性别"
                            },
                            model: {
                              value: _vm.formData.sex,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "sex", $$v)
                              },
                              expression: "formData.sex"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.sex))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "性别2", prop: "sex2" } },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radioButton",
                              title: "性别2",
                              dictCode: "sex",
                              placeholder: "请选择性别2"
                            },
                            model: {
                              value: _vm.formData.sex2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "sex2", $$v)
                              },
                              expression: "formData.sex2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.sex2))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字典表下拉", prop: "user" } },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              placeholder: "请选择用户",
                              dictCode: "sys_user,realname,id"
                            },
                            model: {
                              value: _vm.formData.user,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "user", $$v)
                              },
                              expression: "formData.user"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.user))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "字典表下拉(带条件)", prop: "user2" }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              placeholder: "请选择用户",
                              dictCode:
                                "sys_user,realname,id,username!='admin' order by create_time"
                            },
                            model: {
                              value: _vm.formData.user2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "user2", $$v)
                              },
                              expression: "formData.user2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.user2))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "字典搜索(同步)",
                            prop: "searchValue"
                          }
                        },
                        [
                          _c("j-search-select-tag", {
                            attrs: {
                              placeholder: "请做出你的选择",
                              dictOptions: _vm.searchOptions
                            },
                            model: {
                              value: _vm.formData.searchValue,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "searchValue", $$v)
                              },
                              expression: "formData.searchValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.searchValue))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "字典搜索(异步)",
                            prop: "asyncSelectValue"
                          }
                        },
                        [
                          _c("j-search-select-tag", {
                            attrs: {
                              placeholder: "请做出你的选择",
                              dict: "sys_depart,depart_name,id",
                              pageSize: 6,
                              async: true
                            },
                            model: {
                              value: _vm.formData.asyncSelectValue,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "asyncSelectValue", $$v)
                              },
                              expression: "formData.asyncSelectValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.asyncSelectValue))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字典下拉(多选)", prop: "selMuti" } },
                        [
                          _c("j-multi-select-tag", {
                            attrs: { dictCode: "sex", placeholder: "请选择" },
                            model: {
                              value: _vm.formData.selMuti,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "selMuti", $$v)
                              },
                              expression: "formData.selMuti"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("多选组合(v-model)：" + _vm._s(_vm.formData.selMuti))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "选择部门 自定义返回值",
                            prop: "orgCodes"
                          }
                        },
                        [
                          _c("j-select-depart", {
                            attrs: {
                              "trigger-change": true,
                              customReturnField: "orgCode",
                              multi: true
                            },
                            model: {
                              value: _vm.formData.orgCodes,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "orgCodes", $$v)
                              },
                              expression: "formData.orgCodes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的部门Code(v-model):" + _vm._s(_vm.formData.orgCodes)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "选择部门", prop: "departId" } },
                        [
                          _c("j-select-depart", {
                            attrs: { multi: true },
                            model: {
                              value: _vm.formData.departId,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "departId", $$v)
                              },
                              expression: "formData.departId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的部门ID(v-model):" + _vm._s(_vm.formData.departId)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "选择用户", prop: "userIds" } },
                        [
                          _c("j-select-user-by-dep", {
                            attrs: { multi: true },
                            model: {
                              value: _vm.formData.userIds,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "userIds", $$v)
                              },
                              expression: "formData.userIds"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的用户(v-model):" + _vm._s(_vm.formData.userIds)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "选择用户", prop: "multiUser" } },
                        [
                          _c("j-select-multi-user", {
                            attrs: {
                              "query-config": _vm.selectUserQueryConfig
                            },
                            model: {
                              value: _vm.formData.multiUser,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "multiUser", $$v)
                              },
                              expression: "formData.multiUser"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的用户(v-model):" + _vm._s(_vm.formData.multiUser)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "选择角色", prop: "selectRole" } },
                        [
                          _c("j-select-role", {
                            on: { change: _vm.changeMe },
                            model: {
                              value: _vm.formData.selectRole,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "selectRole", $$v)
                              },
                              expression: "formData.selectRole"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.selectRole))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "选择职务", prop: "selectPosition" }
                        },
                        [
                          _c("j-select-position", {
                            attrs: { buttons: false },
                            model: {
                              value: _vm.formData.selectPosition,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "selectPosition", $$v)
                              },
                              expression: "formData.selectPosition"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中值：" + _vm._s(_vm.formData.selectPosition))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "多选组合", prop: "jCheckbox" } },
                        [
                          _c("j-checkbox", {
                            attrs: { options: _vm.jCheckboxOptions },
                            model: {
                              value: _vm.formData.jCheckbox,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jCheckbox", $$v)
                              },
                              expression: "formData.jCheckbox"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "多选组合(v-model)：" + _vm._s(_vm.formData.jCheckbox)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          staticStyle: { "min-height": "120px" },
                          attrs: { label: "代码输入框", prop: "jCodeEditor" }
                        },
                        [
                          _c("j-code-editor", {
                            staticStyle: { "min-height": "100px" },
                            attrs: { language: "javascript", fullScreen: true },
                            model: {
                              value: _vm.formData.jCodeEditor,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jCodeEditor", $$v)
                              },
                              expression: "formData.jCodeEditor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "代码输入框(v-model)：" + _vm._s(_vm.formData.jCodeEditor)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "日期选择框", prop: "jDate" } },
                        [
                          _c("j-date", {
                            attrs: {
                              showTime: true,
                              dateFormat: "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.formData.jDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jDate", $$v)
                              },
                              expression: "formData.jDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("日期选择框(v-model)：" + _vm._s(_vm.formData.jDate))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          staticStyle: { "min-height": "300px" },
                          attrs: { label: "富文本编辑器", prop: "jEditor" }
                        },
                        [
                          _c("j-editor", {
                            model: {
                              value: _vm.formData.jEditor,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jEditor", $$v)
                              },
                              expression: "formData.jEditor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "富文本编辑器(v-model)：" + _vm._s(_vm.formData.jEditor)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "过长剪切", prop: "jEllipsis" } },
                        [
                          _c("j-ellipsis", {
                            attrs: { value: _vm.formData.jEllipsis, length: 30 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("过长剪切：" + _vm._s(_vm.formData.jEllipsis))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "滑块验证码", prop: "jSlider" } },
                        [
                          _c("j-slider", {
                            on: { onSuccess: _vm.handleJSliderSuccess }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "滑块验证码验证通过：" + _vm._s(_vm.formData.jSlider)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "多选下拉框",
                            prop: "jSelectMultiple"
                          }
                        },
                        [
                          _c("j-select-multiple", {
                            attrs: { options: _vm.jSelectMultipleOptions },
                            model: {
                              value: _vm.formData.jSelectMultiple,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jSelectMultiple", $$v)
                              },
                              expression: "formData.jSelectMultiple"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "多选下拉框(v-model)：" +
                        _vm._s(_vm.formData.jSelectMultiple)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "JModal弹窗" } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "8px" },
                              on: {
                                click: function() {
                                  return (_vm.modal.visible = true)
                                }
                              }
                            },
                            [_vm._v("点击弹出JModal")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "8px" } },
                            [
                              _vm._v("全屏化："),
                              _c("a-switch", {
                                model: {
                                  value: _vm.modal.fullscreen,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modal, "fullscreen", $$v)
                                  },
                                  expression: "modal.fullscreen"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "8px" } },
                            [
                              _vm._v("允许切换全屏："),
                              _c("a-switch", {
                                model: {
                                  value: _vm.modal.switchFullscreen,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modal, "switchFullscreen", $$v)
                                  },
                                  expression: "modal.switchFullscreen"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "j-modal",
                        {
                          attrs: {
                            visible: _vm.modal.visible,
                            width: 1200,
                            title: _vm.modal.title,
                            fullscreen: _vm.modal.fullscreen,
                            switchFullscreen: _vm.modal.switchFullscreen
                          },
                          on: {
                            "update:visible": function($event) {
                              return _vm.$set(_vm.modal, "visible", $event)
                            },
                            "update:fullscreen": function($event) {
                              return _vm.$set(_vm.modal, "fullscreen", $event)
                            }
                          }
                        },
                        [
                          _vm._l(30, function(i, k) {
                            return [
                              _c("p", { key: k }, [
                                _vm._v("这是主体内容，高度是自适应的")
                              ])
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "树字典", prop: "treeDict" } },
                        [
                          _c("j-tree-dict", {
                            attrs: {
                              placeholder: "请选择树字典",
                              parentCode: "B01"
                            },
                            model: {
                              value: _vm.formData.treeDict,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "treeDict", $$v)
                              },
                              expression: "formData.treeDict"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的值(v-model)：" + _vm._s(_vm.formData.treeDict)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "下拉树选择", prop: "treeSelect" } },
                        [
                          _c("j-tree-select", {
                            attrs: {
                              placeholder: "请选择菜单",
                              dict: "sys_permission,name,id",
                              pidField: "parent_id",
                              hasChildField: "is_leaf",
                              pidValue: ""
                            },
                            model: {
                              value: _vm.formData.treeSelect,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "treeSelect", $$v)
                              },
                              expression: "formData.treeSelect"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的值(v-model)：" + _vm._s(_vm.formData.treeSelect)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "下拉树多选",
                            prop: "treeSelectMultiple"
                          }
                        },
                        [
                          _c("j-tree-select", {
                            attrs: {
                              placeholder: "请选择菜单",
                              dict: "sys_permission,name,id",
                              pidField: "parent_id",
                              hasChildField: "is_leaf",
                              pidValue: "",
                              multiple: ""
                            },
                            model: {
                              value: _vm.formData.treeSelectMultiple,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "treeSelectMultiple",
                                  $$v
                                )
                              },
                              expression: "formData.treeSelectMultiple"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的值(v-model)：" +
                        _vm._s(_vm.formData.treeSelectMultiple)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "分类字典树", prop: "selectCategory" }
                        },
                        [
                          _c("j-category-select", {
                            attrs: { pcode: "B01", multiple: true },
                            model: {
                              value: _vm.formData.selectCategory,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "selectCategory", $$v)
                              },
                              expression: "formData.selectCategory"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "选中的值(v-model)：" +
                        _vm._s(_vm.formData.selectCategory)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "cron表达式", prop: "cronExpression" }
                        },
                        [
                          _c("j-easy-cron", {
                            model: {
                              value: _vm.formData.cronExpression,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "cronExpression", $$v)
                              },
                              expression: "formData.cronExpression"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "高级查询" } },
                        [
                          _c("j-super-query", {
                            attrs: { fieldList: _vm.superQuery.fieldList }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "高级查询（自定义按钮）" } },
                        [
                          _c("j-super-query", {
                            attrs: { fieldList: _vm.superQuery.fieldList },
                            scopedSlots: _vm._u([
                              {
                                key: "button",
                                fn: function(ref) {
                                  var isActive = ref.isActive
                                  var isMobile = ref.isMobile
                                  var open = ref.open
                                  var reset = ref.reset
                                  return [
                                    !isActive
                                      ? _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              ghost: "",
                                              icon: "clock-circle"
                                            },
                                            on: {
                                              click: function($event) {
                                                return open()
                                              }
                                            }
                                          },
                                          [_vm._v("高级查询")]
                                        )
                                      : _c(
                                          "a-button-group",
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  ghost: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return open()
                                                  }
                                                }
                                              },
                                              [
                                                _c("a-icon", {
                                                  attrs: {
                                                    type: "plus-circle",
                                                    spin: ""
                                                  }
                                                }),
                                                _c("span", [_vm._v("高级查询")])
                                              ],
                                              1
                                            ),
                                            isMobile
                                              ? _c("a-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    ghost: "",
                                                    icon: "delete"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return reset()
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "图片上传", prop: "imgList" } },
                        [
                          _c("j-image-upload", {
                            attrs: { bizPath: "scott/pic" },
                            model: {
                              value: _vm.formData.imgList,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "imgList", $$v)
                              },
                              expression: "formData.imgList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选中的值(v-model)：" + _vm._s(_vm.formData.imgList))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                {
                  staticStyle: {
                    "margin-top": "65px",
                    "margin-bottom": "50px"
                  },
                  attrs: { gutter: 24 }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "文件上传", prop: "fileList" } },
                        [
                          _c("j-upload", {
                            model: {
                              value: _vm.formData.fileList,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "fileList", $$v)
                              },
                              expression: "formData.fileList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm._v("\n          选中的值(v-model)：\n          "),
                      _vm.formData.fileList.length > 0
                        ? _c("j-ellipsis", {
                            attrs: { value: _vm.formData.fileList, length: 30 }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "特殊查询组件", prop: "jInput" } },
                        [
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 15 } },
                                [
                                  _c("j-input", {
                                    attrs: { type: _vm.jInput.type },
                                    model: {
                                      value: _vm.formData.jInput,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "jInput", $$v)
                                      },
                                      expression: "formData.jInput"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { span: 4 }
                                },
                                [_vm._v("查询类型：")]
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 5 } },
                                [
                                  _c("a-select", {
                                    attrs: { options: _vm.jInput.options },
                                    model: {
                                      value: _vm.jInput.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.jInput, "type", $$v)
                                      },
                                      expression: "jInput.type"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("输入的值(v-model)：" + _vm._s(_vm.formData.jInput))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 15 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          staticStyle: { "min-height": "300px" },
                          attrs: { label: "MarkdownEditor", prop: "content" }
                        },
                        [
                          _c("j-markdown-editor", {
                            model: {
                              value: _vm.formData.content,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "content", $$v)
                              },
                              expression: "formData.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 9 } }, [
                    _vm._v(
                      "\n          输入的值(v-model)：" +
                        _vm._s(_vm.formData.content) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "省市县级联", prop: "areaLinkage1" }
                        },
                        [
                          _c("j-area-linkage", {
                            attrs: { type: "cascader" },
                            model: {
                              value: _vm.formData.areaLinkage1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "areaLinkage1", $$v)
                              },
                              expression: "formData.areaLinkage1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "输入的值(v-model)：" + _vm._s(_vm.formData.areaLinkage1)
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "省市县级联", prop: "areaLinkage2" } },
                    [
                      _c("j-area-linkage", {
                        staticStyle: { float: "left" },
                        attrs: { type: "select" },
                        model: {
                          value: _vm.formData.areaLinkage2,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "areaLinkage2", $$v)
                          },
                          expression: "formData.areaLinkage2"
                        }
                      }),
                      _c("span", { staticStyle: { "margin-left": "25px" } }, [
                        _vm._v(
                          "输入的值(v-model)：" +
                            _vm._s(_vm.formData.areaLinkage2)
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "功能示例：关闭当前页面" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleCloseCurrentPage }
                            },
                            [_vm._v("点击关闭当前页面")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "JPopup示例", prop: "jPopup" } },
                        [
                          _c("j-popup", {
                            attrs: {
                              code: "demo",
                              field: "name",
                              orgFields: "name",
                              destFields: "name",
                              multi: true
                            },
                            model: {
                              value: _vm.formData.jPopup,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "jPopup", $$v)
                              },
                              expression: "formData.jPopup"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 12 } }, [
                    _vm._v("选择的值(v-model)：" + _vm._s(_vm.formData.jPopup))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }